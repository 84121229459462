import React from 'react'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import { useSelector, useDispatch } from 'react-redux'

import { ACTIONS } from '../../../redux/reducer'

const ColumnsPopover = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch()
  const visibleColumns = useSelector(state => state.activeReport.visibleColumns)

  const handleToggleColumn = name => e => {
    const payload = visibleColumns.map(c => {
      if (c.Header === name) {
        return {
          ...c,
          isVisible: e.target.checked,
        }
      }
      return c
    })
    dispatch({ type: ACTIONS.VISIBLE_REPORT_COLUMNS, payload })
  }

  return (
    <Popover
      id="columns-popover"
      open
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2}>
        <FormControl component="fieldset">
          <FormGroup>
            {visibleColumns.map(c => {
              return (
                <FormControlLabel
                  key={c.Header}
                  control={(
                    <Checkbox
                      checked={c.isVisible}
                      onChange={handleToggleColumn(c.Header)}
                      value={c.Header}
                    />
                  )}
                  label={c.Header}
                />
              )
            })}
          </FormGroup>
        </FormControl>
      </Box>
    </Popover>
  )
}

ColumnsPopover.propTypes = {
  anchorEl: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default ColumnsPopover