import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textField: {
    width: 200,
  },
}))

const TextParameter = ({ isDisabled, param, onChange }) => {
  // console.log(param)
  const classes = useStyles()

  const { displayName, value, isRequired, name, helperText, placeholder } = param

  const isValid = (value) => {
    if (!value) return !isRequired
    if (isRequired && (value === null || value.trim() === '')) return false
    return true
  }

  const handleChange = e => {
    const newValue = e.target.value
    onChange(newValue, isValid(newValue))
  }

  return (
    <TextField
      className={classes.textField}
      disabled={isDisabled}
      error={!isValid(value)}
      helperText={helperText}
      id={`int-${name}`}
      InputLabelProps={{
        shrink: true,
      }}
      label={displayName || name}
      onChange={handleChange}
      placeholder={placeholder}
      required={isRequired}
      value={value || ''}
    />
  )
}

TextParameter.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
}

export default TextParameter