export const INITIAL_STATE = {
  activeReport: null,
  apiInfo: null,
  branches: null,
  currentBranchId: null,
  error: false,
  headerHeight: 0,
  filters: [],
  isFilteringInProgress: false,
  isNoReports: false,
  isReportLoading: false,
  isSmallTable: false,
  pageIndex: 0,
  pageSize: 25,
  reportData: null,
  reportRuntimeMs: null,
  reports: [],
  tableWidth: null,
}

export const ACTIONS = {
  BEGIN_FILTERING: 'BEGIN_FILTERING',
  END_FILTERING: 'END_FILTERING',
  FETCH_INITIAL_DATA: 'FETCH_INITIAL_DATA',
  FETCH_REPORT: 'FETCH_REPORT',
  FILTERS: 'FILTERS',
  HEADER_HEIGHT: 'HEADER_HEIGHT',
  PAGE_INDEX: 'PAGE_INDEX',
  PAGE_SIZE: 'PAGE_SIZE',
  RECEIVE_INITIAL_DATA: 'RECEIVE_INITIAL_DATA',
  RECEIVE_REPORT: 'RECEIVE_REPORT',
  SET_ACTIVE_REPORT: 'SET_ACTIVE_REPORT',
  SET_ERROR: 'SET_ERROR',
  SET_PARAM_VALUE: 'SET_PARAM_VALUE',
  SET_CURRENT_BRANCH: 'SET_CURRENT_BRANCH',
  SMALL_TABLE: 'SMALL_TABLE',
  STICKY_HEADER: 'STICKY_HEADER',
  TABLE_WIDTH: 'TABLE_WIDTH',
  VISIBLE_REPORT_COLUMNS: 'VISIBLE_REPORT_COLUMNS',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.BEGIN_FILTERING: {
      if (state.isFilteringInProgress) return state
      return {
        ...state,
        isFilteringInProgress: true,
      }
    }
    case ACTIONS.END_FILTERING: {
      return {
        ...state,
        isFilteringInProgress: false,
      }
    }
    case ACTIONS.SET_CURRENT_BRANCH: {
      if (!state.reports) {
        return {
          ...state,
          currentBranchId: action.payload,
        }
      }
      return {
        ...state,
        currentBranchId: action.payload,
        // autoset the branch type params to the selected branch
        // not sure this is ideal in the reducer, but whatever
        reports: state.reports.map(r => {
          return {
            ...r,
            parsedReportParams: r.parsedReportParams?.map(p => {
              if (p.type !== 'branch') return p
              const branch = state.branches.find(b => parseInt(b.BRANCH_ID, 10) === parseInt(action.payload, 10))
              const newValue = branch ? branch[p.valueField] : null
              return {
                ...p,
                value: newValue,
                isValid: true,
              }
            }),
          }
        }),
      }
    }
    case ACTIONS.FETCH_REPORT:
      return {
        ...state,
        error: false,
        reportData: null,
        reportRuntimeMs: null,
        isReportLoading: true,
      }
    case ACTIONS.FETCH_INITIAL_DATA:
      return {
        ...state,
        error: false,
        branches: [],
        reports: [],
        isNoReports: false,
      }
    case ACTIONS.RECEIVE_REPORT:
      return {
        ...state,
        isReportLoading: false,
        reportData: action.payload.reportData,
        reportRuntimeMs: action.payload.runtimeMs,
      }
    case ACTIONS.RECEIVE_INITIAL_DATA:
      return {
        ...state,
        error: false,
        apiInfo: action.payload.apiInfo,
        branches: action.payload.branches,
        isNoReports: action.payload.reports.length === 0,
        reports: action.payload.reports,
      }
    case ACTIONS.SET_ACTIVE_REPORT: {
      if (!action.payload) {
        return {
          ...state,
          activeReport: null,
          pageIndex: INITIAL_STATE.pageIndex,
          pageSize: INITIAL_STATE.pageSize,
        }
      }
      return {
        ...state,
        activeReport: {
          ...action.payload,
          // don't unset the currentBranchId
          parsedReportParams: action.payload.parsedReportParams ? action.payload.parsedReportParams.map(p => {
            if (p.type !== 'branch') return p
            const branch = state.branches.find(b => parseInt(b.BRANCH_ID, 10) === parseInt(state.currentBranchId, 10))
            const newValue = branch ? branch[p.valueField] : null
            // console.log(newValue)
            return {
              ...p,
              value: newValue,
              isValid: p.isRequired ? !!newValue : true,
            }
          }) : null,
        },
        error: false,
        reportData: null,
        pageIndex: INITIAL_STATE.pageIndex,
        pageSize: INITIAL_STATE.pageSize,
      }
    }
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        isReportLoading: false,
        reportData: null,
      }
    case ACTIONS.SET_PARAM_VALUE:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          parsedReportParams: state.activeReport.parsedReportParams.map(p => {
            if (p.name === action.payload.paramName) {
              return {
                ...p,
                isValid: action.payload.isValid,
                value: action.payload.newValue,
              }
            }
            return p
          }),
        },
      }
    case ACTIONS.STICKY_HEADER:
      return {
        ...state,
        isStickyHeader: action.payload,
      }
    case ACTIONS.SMALL_TABLE:
      return {
        ...state,
        isSmallTable: action.payload,
      }
    case ACTIONS.HEADER_HEIGHT: {
      return {
        ...state,
        headerHeight: action.payload,
      }
    }
    case ACTIONS.PAGE_INDEX: {
      return {
        ...state,
        pageIndex: action.payload,
      }
    }
    case ACTIONS.PAGE_SIZE: {
      return {
        ...state,
        pageIndex: 0,
        pageSize: action.payload,
      }
    }
    case ACTIONS.FILTERS: {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case ACTIONS.TABLE_WIDTH: {
      return {
        ...state,
        tableWidth: action.payload,
      }
    }
    case ACTIONS.VISIBLE_REPORT_COLUMNS: {
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          // to avoid resetting on view change. there is probably a better solution...
          // the conditional is to handle the case where 'No data' is returned on the first run of the report. In this case,
          // visibleColumns will be set if data is returned on the next run.
          isVisibleColumnsInitialised: action.payload.length > 0,
          visibleColumns: action.payload,
        },
      }
    }
    default:
      return state
  }
}