import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  italic: {
    fontStyle: 'italic',
  },
}))

const SortHelperText = () => {
  const classes = useStyles()
  return (
    <Typography variant="body2" className={classes.italic}>
    Click table headers to sort data. Hold the Shift key to sort on multiple columns.
    </Typography>
  )
}

export default SortHelperText