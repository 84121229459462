import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import amber from '@material-ui/core/colors/amber'
import yellow from '@material-ui/core/colors/yellow'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: yellow[50],
    border: `1px solid ${amber[100]}`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 260,
  },
}))(Tooltip)

export default HtmlTooltip
