import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import blueGrey from '@material-ui/core/colors/blueGrey'
import { useSelector } from 'react-redux'

const toolbarHeight = 49 // px

const useStyles = makeStyles(theme => ({
  filterCell: {
    backgroundColor: blueGrey[50],
    color: theme.palette.common.white,
    fontWeight: 700,
    padding: theme.spacing(1),
  },
}))

const ReportTableFilters = ({ headerGroups, top }) => {
  const classes = useStyles()
  const  { isStickyHeader, activeReport: { visibleColumns } } = useSelector(state => state)

  return (
    <>
      {headerGroups.map(headerGroup => {
        const headerGroupProps = headerGroup.getHeaderGroupProps()
        return (
          <TableRow key={`${headerGroupProps.key}-filter`} {...headerGroupProps}>
            {headerGroup.headers.map(column => {
              let isVisible = true
              if (visibleColumns) {
                const visibleColumn = visibleColumns.find(c => c.Header === column.Header)
                if (visibleColumn) isVisible = visibleColumn.isVisible
              }
              if (!isVisible) return null

              // make sure key is unique
              const props = column.getHeaderProps()
              const newProps = {
                ...props,
                key: `${props.key}-filter`,
              }
              return (
                <TableCell
                  align={column.align || 'left'}
                  className={classes.filterCell}
                  // MUI table stickyHeader prop doesnt currently support multiple header rows:
                  // https://github.com/mui-org/material-ui/issues/18019
                  style={{ top: isStickyHeader ? top + toolbarHeight : top }}
                  {...newProps}
                >
                  {column.disableFilters ? '' : column.render('Filter')}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
ReportTableFilters.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  top: PropTypes.number,
}
ReportTableFilters.defaultProps = {
  top: 0,
}

export default ReportTableFilters