import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'

const BooleanParameter = ({ isDisabled, param, onChange }) => {
  // console.log(param)

  const isValid = (value) => {
    return true
  }

  const handleChange = e => {
    const newValue = e.target.checked ? 'true' : 'false'
    onChange(newValue, isValid(newValue))
  }

  const { displayName, value, name, helperText } = param

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox checked={value === 'true'} onChange={handleChange} value="checked" />
        }
        disabled={isDisabled}
        label={displayName || name}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormGroup>
  )
}

BooleanParameter.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
}

export default BooleanParameter