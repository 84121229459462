import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  italic: {
    fontStyle: 'italic',
  },
}))

const ErrorText = ({ message }) => {
  const classes = useStyles()

  return (
    <Typography
      color="error"
      variant="caption"
      className={classes.italic}
    >
      {message}
    </Typography>
  )
}

export default ErrorText