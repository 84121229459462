import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const ReportDescription = ({ description }) => {
  return (
    <ReactMarkdown source={description} />
  )
}

ReportDescription.propTypes = {
  description: PropTypes.string.isRequired,
}

export default ReportDescription
