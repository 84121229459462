import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useSelector } from 'react-redux'

import { isNumeric } from '../../helpers'

const DynamicTotalRow = React.memo((props) => {
  const { firstDataRow, reportColumns, visibleData } = props
  const  { activeReport: { visibleColumns }  } = useSelector(state => state)

  return (
    <TableRow>
      {Object.keys(firstDataRow).map(key => {
        const columnConfig = reportColumns.find(c => c.id === key)
        let isVisible = true
        if (visibleColumns && columnConfig) {
          const visibleColumn = visibleColumns.find(c => c.accessor === columnConfig.id)
          if (visibleColumn) isVisible = visibleColumn.isVisible
        }
        if (!isVisible) return null

        const total = visibleData.reduce((sum, r) => {
          const value = isNumeric(r[key]) ? r[key] : 0
          return sum + value
        }, 0)
        return (
          <TableCell
            key={key}
            align="right"
          >
            {columnConfig && columnConfig.showTotal === true ? total : ''}
          </TableCell>
        )
      })}
    </TableRow>
  )
}, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps))

DynamicTotalRow.propTypes = {
  firstDataRow: PropTypes.object.isRequired,
  visibleData: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
}

// DynamicTotalRow.whyDidYouRender = true

export default DynamicTotalRow