import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import { useTable, useSortBy, useFilters } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'
import ResizeDetector from 'react-resize-detector'
import { makeStyles } from '@material-ui/core/styles'

import DefaultColumnFilter from './DefaultColumnFilter'
import SelectColumnFilter from './SelectColumnFilter'
import TablePaginationActions from './TablePaginationActions'
import ReportTableHeader from './ReportTableHeader'
import ReportTableFilters from './ReportTableFilters'
import ReportTableRow from './ReportTableRow'
import ReportOptions from './ReportOptions'
import SortHelperText from './SortHelperText'
import DynamicTotalRow from './DynamicTotalRow'
import { isNumeric } from '../../helpers'
import { ACTIONS } from '../../redux/reducer'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Report = React.memo((props) => {
  const { reportOptions, reportData, reportName, reportColumns } = props
  const reportOptionsRef = React.useRef(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    activeReport: { isVisibleColumnsInitialised },
    isFilteringInProgress,
    isSmallTable,
    isStickyHeader,
    headerHeight,
    pageIndex,
    pageSize,
    filters: reduxFilters,
  } = useSelector(state => state)

  const columns = React.useMemo(() => {
    if (!reportData) return []
    const firstDataRow = reportData[0]
    if (!firstDataRow) return []

    const out = Object.keys(firstDataRow).map(key => {
      const columnConfig = reportColumns?.find(c => c.id === key)

      let align = 'left'
      let disableFilters = false
      // defaults for numeric data
      if (isNumeric(firstDataRow[key])) {
        align = 'right'
      }
      if (isNumeric(firstDataRow[key]) && (!columnConfig || (columnConfig && !columnConfig.filter ))) {
        disableFilters = true
      }

      // default filter types
      let Filter = DefaultColumnFilter
      if (typeof(firstDataRow[key]) === 'boolean') {
        Filter = SelectColumnFilter
      }

      // no config specified, return defaults
      if (!columnConfig) {
        return {
          Header: key,
          accessor: key,
          align,
          Filter: DefaultColumnFilter,
          disableFilters,
        }
      }

      // console.log(columnConfig)

      let filter = 'text'
      if (columnConfig.filter) filter = columnConfig.filter

      // defaults
      if (columnConfig.type) {
        switch (columnConfig.type) {
        // can't set a default for date, with datetimes too many options
        // case 'date':
        //   Filter = SelectColumnFilter
        //   break
          case 'boolean':
            filter = 'select'
            break
          default:
            break
        }
      }

      switch (filter) {
        case 'text':
          Filter = DefaultColumnFilter
          // filter = (rows, cols, filterValue) => {
          //   console.log('start filtering', filterValue)
          //   const out = rows
          //     .filter(r => {
          //       return r.values[cols[0]].toLowerCase().indexOf(filterValue.toLowerCase()) > -1
          //     })
          //   console.log('end filtering')
          //   return out
          // }
          break
        case 'select':
          Filter = SelectColumnFilter
          // This is not fully documented at the time of writing:
          // https://github.com/tannerlinsley/react-table/blob/master/docs/api/useFilters.md
          // cols is an array, but always (?) contains a single column in our cases
          // anyway, the below passed the filter if the value matches for *any of* the passed columns
          filter = (rows, cols, filterValue) => rows.filter(r => {
            const compareString = filterValue.toString() === '[Blank]' ? '' : filterValue.toString();
            return cols.some(c => {
              const val = r.values[c] || '';
              return val instanceof String ? val : val.toString() === compareString;
            });
          });
          break;
        default:
          Filter = React.memo(() => <div>filter</div>)
      }

      return {
        Header: columnConfig.title || key,
        accessor: key,
        align: columnConfig.align || align,
        type: columnConfig.type,
        format: columnConfig.format,
        currencySymbol: columnConfig.currencySymbol,
        decimalPlaces: columnConfig.decimalPlaces,
        disableSortBy: columnConfig.disableSortBy || false,
        disableFilters: columnConfig.disableFilters || disableFilters,
        Filter,
        filter: filter || 'text',
      }
    })

    return out
  }, [reportData, reportColumns])

  React.useEffect(() => {
    if (isVisibleColumnsInitialised) return // avoid running again on view change. see /redux/reducer.js
    const payload = columns.map(c => ({
      accessor: c.accessor,
      Header: c.Header,
      isVisible: true,
    }))
    dispatch({ type: ACTIONS.VISIBLE_REPORT_COLUMNS, payload })
  }, [dispatch, columns, isVisibleColumnsInitialised])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // page, // page only contains the rows for the active page
    rows, // all filtered rows, used to get the total number of filtered records
    pageCount,
    state: { filters },
  } = useTable(
    {
      columns,
      data: reportData || [],
      initialState: { pageIndex, pageSize, filters: reduxFilters },
    },
    useFilters,
    useSortBy,
  )

  const hasTotalRow = reportColumns?.some(c => c.showTotal === true) ?? false
  const page = rows?.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize) ?? []

  React.useEffect(() => {
    dispatch({ type: ACTIONS.FILTERS, payload: filters })
  }, [dispatch, filters])

  const handleResize = React.useCallback((w, h) => {
    dispatch({ type: ACTIONS.HEADER_HEIGHT, payload: h })
  }, [dispatch])

  const handleChangePage = (e, newPage) => {
    dispatch({ type: ACTIONS.PAGE_INDEX, payload: newPage })
  }

  const handleChangeRowsPerPage = e => {
    dispatch({ type: ACTIONS.PAGE_SIZE, payload: parseInt(e.target.value, 10) })
  }

  const handleSmallTableChange = (value) => {
    dispatch({ type: ACTIONS.SMALL_TABLE, payload: value })
  }
  const handleTableResize = React.useCallback(w => {
    dispatch({ type: ACTIONS.TABLE_WIDTH, payload: w })
  }, [dispatch])

  const rowValues = rows.map(r => r.values)

  if (reportData && reportData.length === 0) return <Typography>No data.</Typography>

  return (
    <Box mt={isStickyHeader ? 0 : 2}>
      {!isStickyHeader && <SortHelperText />}

      <Box display="flex" alignItems="center" ref={reportOptionsRef}>
        <ReportOptions
          isSmallTable={isSmallTable}
          isStickyHeader={isStickyHeader}
          onSmallTableChange={handleSmallTableChange}
          reportData={reportData}
          reportName={reportName}
        />
        <Box flex={1} alignItems="flex-end">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]}
            component="div"
            count={rows.length}
            rowsPerPage={pageSize}
            page={pageIndex > pageCount + 1 ? 0 : pageIndex}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      </Box>
      <Box display="flex">
        {reportData.length > 10000 && ( // filtering should be fast enough with < 10k rows, so no need to show the spinner. 10k is an arbitrary value.
          <Backdrop className={classes.backdrop} open={isFilteringInProgress}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Table stickyHeader={isStickyHeader} size={isSmallTable ? 'small' : 'medium'} {...getTableProps()}>
          <TableHead>
            <ReportTableHeader headerGroups={headerGroups} onResize={handleResize} />
            {!reportOptions.disableFilters === true && (
              <ReportTableFilters headerGroups={headerGroups} top={(isStickyHeader && headerHeight) || 0} />
            )}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              // Remove cells.row property, it's not needed and the circular reference in the data
              // affects the custom React.memo areEqual function in ReportTableRow.
              const cells = row.cells
                .map(c => ({
                  column: c.column,
                  value: c.value,
                  getCellProps: c.getCellProps,
                  render: c.render,
                }))
              const rowProps = row.getRowProps()
              return (
                <ReportTableRow
                  key={row.index}
                  isEvenRow={i % 2 !== 0}
                  rowProps={rowProps}
                  cells={cells}
                />
              )
            })}
          </TableBody>
          {hasTotalRow && (
            <TableFooter>
              <DynamicTotalRow
                firstDataRow={reportData[0]}
                reportColumns={reportColumns}
                visibleData={rowValues}
              />
            </TableFooter>
          )}
          <ResizeDetector handleWidth onResize={handleTableResize} nodeType="tbody" />
        </Table>
      </Box>
      {!isStickyHeader && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={pageSize}
          page={pageIndex > pageCount + 1 ? 0 : pageIndex}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Box>
  )
})

Report.propTypes = {
  reportColumns: PropTypes.arrayOf(PropTypes.object),
  reportData: PropTypes.arrayOf(PropTypes.object),
  reportOptions: PropTypes.object,
  reportName: PropTypes.string,
}
Report.defaultProps = {
  reportColumns: [],
  reportData: [],
  reportName: '',
  reportOptions: {},
}

Report.whyDidYouRender = true
Report.displayName = 'Report'

export default Report