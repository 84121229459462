import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import { useDispatch, useSelector } from 'react-redux'

import { ACTIONS } from '../../../redux/reducer'
import HtmlTooltip from '../../HtmlTooltip'
import DownloadCSVButton from '../DownloadCSVButton'
import ColumnsPopover from './ColumnsPopover'

const ReportOptions = ({ reportData, reportName }) => {
  const dispatch = useDispatch()
  const { isSmallTable, isStickyHeader } = useSelector(state => state)

  const onStickyHeaderChange =  (value) => {
    dispatch({ type: ACTIONS.STICKY_HEADER, payload: value })
  }
  const onSmallTableChange =  (value) => {
    dispatch({ type: ACTIONS.SMALL_TABLE, payload: value })
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <Toolbar disableGutters>
      <DownloadCSVButton csvData={reportData} reportName={reportName} />
      <HtmlTooltip title={isSmallTable ? 'Expand rows' : 'Compress rows'}>
        <IconButton aria-label="toggle expand rows" onClick={() => onSmallTableChange(!isSmallTable)}>
          <Icon className={`fas ${isSmallTable ? 'fa-expand-alt' : 'fa-compress-alt'}`} />
        </IconButton>
      </HtmlTooltip>
      <HtmlTooltip title={isStickyHeader ? 'Exit full screen' : 'Full screen'}>
        <IconButton aria-label="toggle sticky header" onClick={() => onStickyHeaderChange(!isStickyHeader)}>
          <Icon className={`fas ${!isStickyHeader ? 'fa-expand' : 'fa-compress'}`} />
        </IconButton>
      </HtmlTooltip>
      <HtmlTooltip title="Columns">
        <IconButton aria-label="show/hide columns" onClick={handleClick}>
          <Icon className={'fas fa-columns'} />
        </IconButton>
      </HtmlTooltip>
      {anchorEl && (
        <ColumnsPopover
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </Toolbar>
  )
}

ReportOptions.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportName: PropTypes.string.isRequired,
}

export default ReportOptions