import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import sortBy from 'lodash/sortBy'

import ErrorText from '../ErrorText'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
}))

const BranchParameter = ({ branches, isDisabled, param, onChange }) => {
  // console.log(param, branches)
  const classes = useStyles()

  const { branchType, displayName, value, name, helperText, valueField, isRequired } = param

  if (!valueField) {
    return (
      <ErrorText
        message={`valueField is missing for branch parameter ${name}. Please raise a ticket with IT Support, quoting the report name.`}
      />
    )
  }

  const isValid = (value) => {
    if (!value) return !isRequired
    if (isRequired && (value === null || value === 'select' || value.trim() === '')) return false
    return true
  }

  const handleChange = e => {
    const newValue = e.target.value
    onChange(newValue, isValid(newValue))
  }

  const filteredBranches = branches.filter(b => {
    if (branchType === 'jones' && b.BRANCH_DESC.substring(0, 2).toUpperCase() !== 'JB') return false
    if (branchType === 'pavers' && b.BRANCH_DESC.substring(0, 2).toUpperCase() === 'JB') return false
    return true
  })

  return (
    <FormControl className={classes.formControl} disabled={isDisabled} error={!isValid(value)} required={isRequired}>
      <InputLabel shrink id={`select-${name}`}>
        {displayName || name}
      </InputLabel>
      <Select
        labelId={`select-${name}-label`}
        id={`select-${name}-placeholder-label`}
        value={isRequired ? (value || 'select') : (value || '')}
        onChange={handleChange}
        displayEmpty
      >
        {isRequired && (
          <MenuItem value="select"><em>Select branch</em></MenuItem>
        )}
        {!isRequired && (
          <MenuItem value="">All branches</MenuItem>
        )}
        {sortBy(filteredBranches, 'BRANCH_CODE').map(b => (
          <MenuItem
            key={b.BRANCH_ID}
            value={b[valueField].toString()}
          >
            {b.BRANCH_CODE} {b.BRANCH_DESC}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

BranchParameter.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
}

export default BranchParameter