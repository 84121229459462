import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { useDispatch } from 'react-redux'
import DebounceInput from 'react-debounce-input'

import HtmlTooltip from '../HtmlTooltip'
import { ACTIONS } from '../../redux/reducer'

// Default UI for filtering
const DefaultColumnFilter = ({
  column,
}) => {
  const { id, filterValue, setFilter } = column
  const handleClearFilter = () => handleChange({ target: { value: ''} })
  const dispatch = useDispatch()

  const handleChange = e => {
    dispatch({ type: ACTIONS.PAGE_INDEX, payload: 0 })
    dispatch({ type: ACTIONS.BEGIN_FILTERING })
    setTimeout(() => {
      setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      dispatch({ type: ACTIONS.END_FILTERING })
    }, 0)
  }

  return (
    <DebounceInput
      minLength={2}
      debounceTimeout={250}
      id={`filter-${id}`}
      onChange={handleChange}
      value={filterValue || ''}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <HtmlTooltip title="Clear filter">
              <IconButton
                aria-label="clear filter"
                onClick={handleClearFilter}
                size="small"
                disableRipple
                disableFocusRipple
                edge="end"
              >
                <Icon className="fas fa-times" fontSize="small" />
              </IconButton>
            </HtmlTooltip>
          </InputAdornment>
        ),
      }}
      element={TextField}
    />
  )
}

export default DefaultColumnFilter