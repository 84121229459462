import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useSelector } from 'react-redux'

import Report from './Report'

const StickyHeaderLayout = () => {
  const {
    activeReport,
    isReportLoading,
    reportData,
    tableWidth,
  } = useSelector(state => state)

  const reportColumns = activeReport?.parsedReportColumns

  // handle reports with columns > viewport width
  const width = Math.max(tableWidth, window.innerWidth)

  return (
    <Box minWidth="100%" height="100%">
      <Paper style={{ width }}>
        <Report
          isLoading={isReportLoading}
          reportColumns={reportColumns}
          reportData={reportData}
          reportOptions={activeReport.parsedReportOptions || {}}
          reportName={activeReport.reportName}
        />
      </Paper>
    </Box>
  )
}

export default StickyHeaderLayout
