import React from 'react'
import PropTypes from 'prop-types'

import BranchParameter from './ReportParams/BranchParameter'

// We might as well reuse the <BranchParameter /> component
const SelectCurrentBranch = ({ branches, onChange, username }) => {
  return (
    <BranchParameter
      branches={branches}
      isDisabled={false}
      param={{
        branchType: username === 'jonesreports' ? 'jones' : 'pavers',
        displayName: 'Select branch',
        value: '',
        name: 'branch',
        valueField: 'BRANCH_ID',
        isRequired: true,
      }}
      onChange={onChange}
    />
  )
}

SelectCurrentBranch.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  username: PropTypes.oneOf(['jonesreports', 'paversreports']).isRequired,
}

export default SelectCurrentBranch