import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import formatDate from 'date-fns/format'

import HtmlTooltip from '../HtmlTooltip'

const DownloadCSVButton = ({ csvData, reportName }) => {
  if (!csvData ||  csvData.length === 0) return null

  const headers = Object.keys(csvData[0]).map(col => ({
    label: col,
    key: col,
  }))

  const hyphenated = reportName.split(' ').join('-').toLowerCase()
  const now = formatDate(new Date(), 'yyyy-MM-dd-HH:mm:ss')

  const filename = `${now}-${hyphenated}.csv`

  return (
    <CSVLink
      // We can't render a MUI Link component because <CSVLink /> generates an <a /> tag,
      // so just pass the classes
      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
      data={csvData}
      headers={headers}
      filename={filename}
    >
      <Box display="inline-flex" alignItems="center">
        <HtmlTooltip title="Download CSV file">
          <IconButton aria-label="download csv file"  className="fa fa-file-csv" />
        </HtmlTooltip>
      </Box>
    </CSVLink>
  )
}

DownloadCSVButton.propTypes = {
  csvData: PropTypes.arrayOf(PropTypes.object),
  reportName: PropTypes.string.isRequired,
}
DownloadCSVButton.defaultProps = {
  csvData: null,
}

export default DownloadCSVButton
