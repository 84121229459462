import { createMuiTheme } from '@material-ui/core/styles'
import { paversTheme } from '@paversltd/react-stiletto-components'

const originalTheme = createMuiTheme(paversTheme)
// console.log(originalTheme)
const theme = {
  ...originalTheme,
  overrides: {
    ...originalTheme.overrides,
    // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TableSortLabel/TableSortLabel.js
    MuiTableSortLabel: {
      ...originalTheme.overrides.MuiTableSortLabel,
      root: {
        '&:focus': {
          color: null,
        },
        '&:hover': {
          color: null,
          '& $icon': {
            opacity: 0.5,
          },
        },
        '&$active': {
          color: null,
          fontSize: originalTheme.typography.fontSize + 2,
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            opacity: 1,
            height: originalTheme.typography.fontSize + 2,
            width: originalTheme.typography.fontSize + 2,
            color: null,
          },
        },
      },
    },
  },
}

export default theme