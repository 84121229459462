import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  paper: {
    overflow: 'auto',
    padding: theme.spacing(2),
    width: '100%',
  },
}))

const Section = ({ children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      {children}
    </Paper>
  )
}

Section.propTypes = {
  children: PropTypes.any,
}
Section.defaultProps = {
  children: null,
}

export default Section