import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import ErrorText from '../ErrorText'
import DateParameter from './DateParameter'
import NumberParameter from './NumberParameter'
import TextParameter from './TextParameter'
import BooleanParameter from './BooleanParameter'
import BranchParameter from './BranchParameter'

const useStyles = makeStyles(theme => ({
  gridCell: {
    margin: theme.spacing(1, 0),
  },
}))

const ReportParams = ({ branches, currentBranchId, isDisabled, params, onParamChange }) => {
  const classes = useStyles()

  const renderParam = p => {
    const handleParamChange = (n, isValid) => onParamChange(p.name, n, isValid)

    switch (p.type ? p.type.trim().toLowerCase() : '') {
      case 'int':
        return (
          <NumberParameter
            isDisabled={isDisabled}
            onChange={handleParamChange}
            param={p}
          />
        )
      case 'date':
      case 'datetime':
        return (
          <DateParameter
            isDisabled={isDisabled}
            onChange={handleParamChange}
            param={p}
          />
        )
      case 'varchar':
      case 'text':
        return (
          <TextParameter
            isDisabled={isDisabled}
            onChange={handleParamChange}
            param={p}
          />
        )
      case 'bit': // e.g (SN Pending). send 'true' or 'false'
        return (
          <BooleanParameter
            isDisabled={isDisabled}
            onChange={handleParamChange}
            param={p}
          />
        )
      case 'branch':
        return (
          <BranchParameter
            branches={branches}
            isDisabled={isDisabled}
            onChange={handleParamChange}
            param={p}
          />
        )
      default:
        return (
          <ErrorText message={`Error: Parameter type ${p.type} not handled.`} />
        )
    }
  }

  return (
    <Grid container>
      {params.map(p => {
        if (p.type === 'branch' && currentBranchId) return null // branch params have been autoset
        return (
          <Grid key={p.name} item sm={12} md={6} lg={3} className={classes.gridCell}>
            <Box display="flex" alignItems="flex-start" width="100%" height="100%">
              {renderParam(p)}
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

ReportParams.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentBranchId: PropTypes.number,
  isDisabled: PropTypes.bool.isRequired,
  onParamChange: PropTypes.func.isRequired,
  params: PropTypes.arrayOf(PropTypes.object).isRequired,
}
ReportParams.defaultProps = {
  currentBranchId: null,
}

export default ReportParams
