import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch } from 'react-redux'

import { ACTIONS } from '../../redux/reducer'
const ALL = '[[all]]'

const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const dispatch = useDispatch()

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id] === '' ? '[Blank]' : row.values[id])
    })
    const sorted = [...options.values()].sort()
    // console.log(sorted)
    return sorted
  }, [id, preFilteredRows])
    .filter(o => !!o || o === false) // remove nulls etc

  const handleChange = e => {
    dispatch({ type: ACTIONS.PAGE_INDEX, payload: 0 })

    const v = e.target.value
    if (v === false) {
      setFilter('false')
    } else {
      setFilter(v.toString() === ALL ? undefined : v)
    }
  }

  if (options.length === 0) return null

  // Render a multi-select box
  return (
    <Select
      fullWidth
      value={filterValue || ALL}
      onChange={handleChange}
    >
      <MenuItem value={ALL}>Show all</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option.toString()}>
          {option.toString()}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectColumnFilter