/*
MUI table stickyHeader prop doesnt currently support multiple header rows:
https://github.com/mui-org/material-ui/issues/18019.
The resize detector is only rendered for the first headerGroup.
It's a bit hacky, and this will probably break if we every need to render tables with grouped headers, but
hopefully by then MUI will hjave fixed the above.
*/

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ResizeDetector from 'react-resize-detector'
import { useSelector } from 'react-redux'

const toolbarHeight = 49 // px

const useStyles = makeStyles(theme => ({
  headerCell: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: 700,
    padding: theme.spacing(1),
  },
}))

const ReportTableHeader = ({ headerGroups, onResize }) => {
  const classes = useStyles()
  const { isStickyHeader, activeReport: { visibleColumns } } = useSelector(state => state)

  return (
    <>
      {headerGroups.map((headerGroup, i) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps()
        return (
          <TableRow {...headerGroupProps}>
            {headerGroup.headers.map(column => {
              let isVisible = true
              if (visibleColumns) {
                const visibleColumn = visibleColumns.find(c => c.Header === column.Header)
                if (visibleColumn) isVisible = visibleColumn.isVisible
              }
              if (!isVisible) return null

              return (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  align={column.align || 'left'}
                  className={classes.headerCell}
                  // MUI table stickyHeader prop doesnt currently support multiple header rows:
                  // https://github.com/mui-org/material-ui/issues/18019
                  style={{ top: isStickyHeader ? toolbarHeight : null }}
                >
                  {column.disableSortBy ? column.render('Header') : (
                    <TableSortLabel
                      active={column.isSorted ||  column.isSortedDesc}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      {column.render('Header')}
                    </TableSortLabel>
                  )}
                </TableCell>
              )
            })}
            {i === 0 && <ResizeDetector handleHeight onResize={onResize} nodeType="td" />}
          </TableRow>
        )
      })}
    </>
  )
}
ReportTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  onResize: PropTypes.func,
}
ReportTableHeader.defaultProps = {
  onResize: () => {},
}

export default ReportTableHeader