export const parseError = e => {
  try {
    // Axios errors
    if (e.request) {
      // The request was made but no response was received
      // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      // Axios request timeouts get handled here
      return e.message
    }

    let out
    // Handle errors returned from the API, including legacy endpoints.
    // This can probably be simplified.
    if (e.response && e.response.data && e.response.data.errorMessage && typeof e.response.data.errorMessage === 'string') {
      return e.response.data.errorMessage
    }

    if (e.response && e.response.data && !Array.isArray(e.response.data) && typeof e.response.data === 'object') {
      out = `${e.response.status} - ${e.response.statusText}.`
      if (e.response.data.errorMessage && e.response.data.errorMessage.originalError) {
        out = `${out} ${e.response.data.errorMessage.originalError.message}`
      }
      return out
    }

    if (e.response && e.response.data && Array.isArray(e.response.data) && e.response.data.length > 0) {
      out = `${e.response.status}`
      if (e.response.statusText) {
        out = `${out} - ${e.response.statusText}`
      }
      if (e.response.data[0].content && e.response.data[0].content.errorMessage) {
        out = `${out} - ${e.response.data[0].content.errorMessage.message}`
      } else if (e.response.data[0].message) {
        out = `${out} - ${e.response.data[0].message}`
      }
      return out
    }

    return e.toString()
  } catch (e) {
    return 'Unknown error.'
  }
}

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n)

export const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}
