import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import { isNumeric } from '../../helpers'

const useStyles = makeStyles(theme => ({
  textField: {
    width: 200,
  },
}))

const NumberParameter = ({ isDisabled, param, onChange }) => {
  // console.log(param)
  const classes = useStyles()

  const isValid = (value) => {
    const { isRequired, min, max } = param
    if (isRequired && !isNumeric(value)) return false
    if (!isRequired && value && value.toString().trim() !== '' && !isNumeric(value)) return false
    if (max && parseFloat(value) > max) return false
    if (min && parseFloat(value) < min) return false
    return true
  }

  const handleChange = e => {
    const newValue = e.target.value
    onChange(newValue, isValid(newValue))
  }

  const { displayName, min, max, value, isRequired, step, name, helperText, placeholder } = param

  return (
    <TextField
      className={classes.textField}
      disabled={isDisabled}
      error={!isValid(value)}
      helperText={helperText}
      id={`int-${name}`}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        max: max,
        min: min,
        step: step || 1,
      }}
      label={displayName || name}
      onChange={handleChange}
      placeholder={placeholder}
      required={isRequired}
      type="number"
      value={value || ''}
    />
  )
}

NumberParameter.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
}

export default NumberParameter