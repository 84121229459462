import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import formatDate from 'date-fns/format'
import isDateValid from 'date-fns/isValid'
import parseDate from 'date-fns/parse'
import differenceInDays from 'date-fns/differenceInDays'
import startOfDay from 'date-fns/startOfDay'

const DATE_FORMAT = 'yyyy-MM-dd'

const useStyles = makeStyles(theme => ({
  datePicker: {
    marginBottom: 0,
    marginTop: 0,
  },
}))

const DateParameter = ({ isDisabled, param, onChange }) => {
  // console.log(param)
  const classes = useStyles()

  const isValid = value => {
    const { disableFuture, disablePast, isRequired } = param
    if (isRequired && !value) return false
    const date = typeof(value) === 'string' ? parseDate(value, DATE_FORMAT, new Date()) : value
    if (disableFuture && differenceInDays(
      startOfDay(date),
      startOfDay(new Date()),
    ) > 0) return false
    if (disablePast && differenceInDays(
      startOfDay(date),
      startOfDay(new Date()),
    ) < 0) return false
    return true
  }

  const handleDateChange = date => {
    if (isDateValid(date)) {
      onChange(formatDate(date, DATE_FORMAT), isValid(date))
      return
    }
    if (date === null) {
      onChange(date, isValid(date))
      return
    }
    onChange(date, false)
  }

  const { disableFuture, disablePast, displayName, name, isRequired, value, helperText } = param

  return (
    <KeyboardDatePicker
      className={classes.datePicker}
      id={`date-picker-inline-${name}`}
      disableFuture={disableFuture}
      disablePast={disablePast}
      disableToolbar
      disabled={isDisabled}
      error={!isValid(value)}
      format="yyyy-MM-dd"
      helperText={helperText}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      label={displayName || name}
      margin="normal"
      onChange={handleDateChange}
      required={isRequired}
      value={value ? parseDate(value, DATE_FORMAT, new Date()) : null}
      // views={['date', 'month', 'year']}
      variant="inline"
    />
  )
}

DateParameter.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
}

export default DateParameter