import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

// const useStyles = makeStyles(theme => ({
//   italic: {
//     fontStyle: 'italic',
//   },
// }))

const ErrorText = ({ message }) => {
  // const classes = useStyles()

  return (
    <Typography>
      You don't have the required permissions to view these reports. If you think this is a mistake, please raise a ticket with IT Support.
    </Typography>
  )
}

export default ErrorText