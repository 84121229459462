import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import clsx from 'clsx'
import formatDate from 'date-fns/format'
import addMinutes from 'date-fns/addMinutes'
import currency from 'currency.js'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  rowStriped: {
    backgroundColor: grey[100],
  },
}))

const ReportTableRow = ({ cells, rowProps, isEvenRow }) => {
  const classes = useStyles()
  const  { activeReport: { visibleColumns }  } = useSelector(state => state)

  return (
    <TableRow {...rowProps}>
      {cells.map(cell => {
        const { column } = cell

        let isVisible = true
        if (visibleColumns) {
          const visibleColumn = visibleColumns.find(c => c.Header === column.Header)
          if (visibleColumn) isVisible = visibleColumn.isVisible
        }
        if (!isVisible) return null

        return (
          <TableCell
            {...cell.getCellProps()}
            align={column.align || 'left'}
            className={clsx({ [classes.rowStriped]: isEvenRow })}
          >
            {cell.render(() => {
              let formattedValue = cell.value
              if (typeof formattedValue === 'boolean') formattedValue = formattedValue.toString()

              switch (cell.column.type) {
                case 'date': {
                  if (cell.column.format && formattedValue && formattedValue !== '') {
                    try {
                      const d = new Date(formattedValue)
                      formattedValue = formatDate(addMinutes(d, d.getTimezoneOffset()), column.format)
                    } catch (e) {}
                  }
                  break
                }

                case 'currency': {
                  try {
                    formattedValue = currency(formattedValue, {
                      formatWithSymbol: !!column.currencySymbol,
                      symbol: column.currencySymbol,
                    }).format()
                  } catch (e) {}
                  break
                }

                default:
                  break
              }

              // decimal places
              if (column.decimalPlaces) {
                try {
                  formattedValue = formattedValue.toFixed(column.decimalPlaces)
                } catch (e) {}
              }

              return formattedValue
            })}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

ReportTableRow.propTypes = {
  isEvenRow: PropTypes.bool.isRequired,
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowProps: PropTypes.object.isRequired,
}

// ReportTableRow.whyDidYouRender = true

export default ReportTableRow