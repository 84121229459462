import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { Helmet } from 'react-helmet'
import { loadCSS } from 'fg-loadcss'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import formatDate from 'date-fns/format'
import subDays from 'date-fns/subDays'
import { createStore } from 'redux'
import { Provider, useDispatch, useSelector } from 'react-redux'
import slugify from '@sindresorhus/slugify'

import StandardLayout from './components/StandardLayout'
import StickyHeaderLayout from './components/StickyHeaderLayout'
import { parseError } from './helpers'
import rootReducer, { ACTIONS } from './redux/reducer'

const toolbarHeight = 49 // px
const DATE_FORMAT = 'yyyy-MM-dd'

const reduxStore = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

const setDefaultParamValues = (params) => {
  // console.log(params)
  params.forEach(p => {
    // set default values
    switch (p.type) {
      case 'bit':
      case 'boolean':
        if (p.defaultChecked) {
          p.value = (p.defaultChecked === true) ? 'true' : 'false'
        } else {
          p.value = 'false'
        }
        break
      case 'date':
      case 'datetime':
        if (p.defaultValue) {
          switch (p.defaultValue) {
            case 'yesterday':
              p.value = formatDate(subDays(new Date(), 1), DATE_FORMAT)
              break
            default:
              p.value = formatDate(new Date(), DATE_FORMAT)
          }
        } else {
          p.value = formatDate(new Date(), DATE_FORMAT)
        }
        break
      default:
        if (p.defaultValue) {
          p.value = p.defaultValue
        } else {
          p.value = null
        }
        switch (p.defaultValue) {
          case 'today':
            p.value = formatDate(new Date(), DATE_FORMAT)
            break
          case 'yesterday':
            p.value = formatDate(subDays(new Date(), 1), DATE_FORMAT)
            break
          default:
            p.value = p.defaultValue
        }
    }

    p.isValid = (p.defaultValue || !p.isRequired) ? true : false
  })
}

const App = ({ accessToken, isProduction, username, baseUrl }) => {
  const dispatch = useDispatch()
  const [path, setPath] = useState({path: '/', options: null})

  const {
    activeReport,
    branches,
    isStickyHeader,
    reports,
  } = useSelector(state => state)

  // Load font awesome CSS: https://material-ui.com/components/icons/#FontAwesome.js
  React.useEffect(() => {
    let didCancel = false
    if (!didCancel) {
      loadCSS(
        'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
        document.querySelector('#font-awesome-css'),
      )
    }
    return () => {
      didCancel = true
    }
  }, [])

  React.useEffect(() => {
    let didCancel = false

    // fetch list of reports + config, list of branches etc.
    const loadInitialData = async () => {
      if (didCancel) return
      if (!accessToken) return // avoid making unnescessary request after logout
      dispatch({ type: ACTIONS.FETCH_INITIAL_DATA })

      try {
        const apiInfoPromise = isProduction
          ? Promise.resolve({
            data: null,
          })
          : axios.get(`${baseUrl}/info`, {
            headers: {
              'x-jwt-token': accessToken,
            },
          })
        const reportsPromise = axios.get(`${baseUrl}/reports`, {
          headers: {
            'x-jwt-token': accessToken,
          },
        })
        const branchPromise = axios.get(`${baseUrl}/branch`, {
          headers: {
            'x-jwt-token': accessToken,
          },
        })
        const [apiInfoResponse, reportsResponse, branchResponse] = await Promise.all([
          apiInfoPromise,
          reportsPromise,
          branchPromise,
        ])

        const { reportData } = reportsResponse.data
        // Parse the JSON strings stored in the SQL dbs
        const parsedReportData = reportData.map(r => {
          let reportOptions
          try {
            reportOptions = JSON.parse(r.reportOptions)
            if (reportOptions.length === 0) {
              reportOptions = null // activeReport.reportParams === "[]" case
            }
          } catch (e) {
            reportOptions = null
          }

          let reportColumns
          try {
            reportColumns = JSON.parse(r.reportColumns)
            if (reportColumns.length === 0) {
              reportColumns = null // activeReport.reportParams === "[]" case
            }
          } catch (e) {
            reportColumns = null
          }

          return {
            parsedReportOptions: reportOptions || {},
            parsedReportColumns: reportColumns || [],
            ...r,
          }
        })

        if (didCancel) return
        dispatch({
          type: ACTIONS.RECEIVE_INITIAL_DATA,
          payload: {
            apiInfo: apiInfoResponse.data,
            branches: branchResponse.data.branchData,
            reports: sortBy(parsedReportData, 'reportName'),
          },
        })
      } catch (e) {
        if (didCancel) return
        dispatch({ type: ACTIONS.SET_ERROR, payload: parseError(e) })
      }
    }

    loadInitialData()

    return () => {
      didCancel = true
    }
  }, [accessToken, baseUrl, dispatch, isProduction])

  const handleReportChange = React.useCallback(async reportID => {
    if (!reportID) {
      dispatch({ type: ACTIONS.SET_ACTIVE_REPORT, payload: null })
      setPath({ path: '/', options: null })
      return
    }
    const report = reports.find(r => r.reportID.toString() === reportID.toString())
    if (!report) {
      dispatch({ type: ACTIONS.SET_ACTIVE_REPORT, payload: null })
      setPath({ path: '/', options: null })
      return
    }
    // console.log(report)

    // Programmatically get the actual parameters defined for the SP
    // We then compare them against the settings defined in the Pavers_reports table, and update them to use those settings.
    // This allows any SPs to be added as reports without having to add the JSON settings
    let url = `${baseUrl}/reports/params/${report.reportID}`
    const response = await axios.get(url, {
      headers: {
        'x-jwt-token': accessToken,
      },
    })
    const allReportParams = response.data.reportParams

    let configuredReportParams
    try {
      configuredReportParams = JSON.parse(report.reportParams)
      if (configuredReportParams.length === 0) {
        configuredReportParams = null // activeReport.configuredReportParams === "[]" case
      } else {
        setDefaultParamValues(configuredReportParams)
      }
    } catch (e) {
      configuredReportParams = null
    }

    report.parsedReportParams = allReportParams?.map(p => {
      const configuredParam = configuredReportParams?.find(pp => pp.name === p.Parameter_name)
      if (!configuredParam) return {
        name: p.Parameter_name,
        type: p.Type,
      }
      return {
        ...configuredParam,
      }
    }) ?? []
    setDefaultParamValues(report.parsedReportParams)

    dispatch({ type: ACTIONS.SET_ACTIVE_REPORT, payload: report})
    setPath({ path: '/', options: slugify(report.reportName) })
  }, [accessToken, baseUrl, dispatch, reports])

  React.useEffect(() => {
    if (!path.options) return
    const report = reports.find(r => slugify(r.reportName.toLowerCase()) === path.options.toLowerCase())
    if (report) {
      handleReportChange(report.reportID)
    }
  }, [reports, handleReportChange, path.options])

  const handleParamChange = React.useCallback((paramName, newValue, isValid) => {
    dispatch({ type: ACTIONS.SET_PARAM_VALUE, payload: { paramName, newValue, isValid } })
  }, [dispatch])

  const handleCurrentBranchChange = React.useCallback(branchId => {
    dispatch({
      type: ACTIONS.SET_CURRENT_BRANCH,
      payload: parseInt(branchId, 10),
    })
  }, [dispatch])

  let helper
  let spName
  let parsedReportParams
  let reportID
  if (activeReport) {
    helper = activeReport.helper
    spName = activeReport.spName
    parsedReportParams = activeReport.parsedReportParams
    reportID = activeReport.reportID
  }

  const fetchReport = React.useCallback(async () => {
    let url = `${baseUrl}/reports/${reportID}?datasource=${helper}&spName=${spName}`

    // Build parameters
    url = `${url}&params=` // this is required by the API regardless of whether there are actually any params
    if (parsedReportParams && parsedReportParams.length > 0) {
      parsedReportParams.forEach(p => {
        url = `${url}${p.name.replace('@', '')}%3D${p.value || ''},`
      })
      url = url.slice(0, -1)
    }

    try {
      dispatch({ type: ACTIONS.FETCH_REPORT })
      const response = await axios.get(url, {
        headers: {
          'x-jwt-token': accessToken,
        },
      })
      dispatch({
        type: ACTIONS.RECEIVE_REPORT,
        payload: {
          reportData: JSON.parse(response.data.reportResults),
          runtimeMs: response.data.runtimeMs,
        },
      })
    } catch (e) {
      dispatch({ type: ACTIONS.SET_ERROR, payload: parseError(e) })
    }
  }, [baseUrl, reportID, helper, spName, parsedReportParams, dispatch, accessToken])

  if (!branches) return null // not loaded yet

  return (
    <>
      <Helmet>
        <title>{activeReport?.reportName ?? 'Pavers Reporting Tool'}</title>
      </Helmet>

      <Box p={isStickyHeader ? 0 : 3} mt={`${toolbarHeight}px`} minWidth="100%">
        {isStickyHeader ? (
          <StickyHeaderLayout toolbarHeight={toolbarHeight} />
        ) : (
          <StandardLayout
            username={username}
            fetchReport={fetchReport}
            handleCurrentBranchChange={handleCurrentBranchChange}
            handleReportChange={handleReportChange}
            handleParamChange={handleParamChange}
          />
        )}
      </Box>
    </>
  )
}

App.propTypes = {
  username: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  isProduction: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
}

App.whyDidYouRender = true
App.displayName = 'App'

const AppWithRedux = (props) => {
  return (
    <Provider store={reduxStore}>
      <App {...props} />
    </Provider>
  )
}

export default AppWithRedux