import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ACTIONS } from '../redux/reducer'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
}))

const ReportSelect = ({ isDisabled, reports, onChange }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { activeReport } = useSelector(state => state)
  const activeReportID = activeReport?.reportID ?? 'select'

  const handleChange = e => {
    const report = reports.find(r => r.reportID.toString() === e.target.value)
    if (!report) {
      dispatch({ type: ACTIONS.SET_ACTIVE_REPORT, payload: null })
      history.push('/')
      return
    }
    onChange(e.target.value)
  }

  const value = activeReportID?.toString() ?? 'select'

  return (
    <FormControl className={classes.formControl} disabled={isDisabled}>
      <Select
        labelId="report-select-label"
        id="report-select"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value="select">Choose a report</MenuItem>
        {reports.map(r => (
          <MenuItem key={r.reportID} value={r.reportID.toString()}>
            {r.reportName}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Report missing? Raise a ticket with IT Support.</FormHelperText>
    </FormControl>
  )
}

ReportSelect.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  reports: PropTypes.arrayOf(PropTypes.object),
}
ReportSelect.defaultProps = {
  activeReportID: null,
  onChange: () => {},
  reports: [],
}

export default ReportSelect