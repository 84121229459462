import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { LoginForm, StilettoToolbar } from '@paversltd/react-stiletto-components'
import { AuthProvider, AuthenticatedComponent } from '@paversltd/react-stiletto-auth'
import DateFnsUtils from '@date-io/date-fns'

import '@paversltd/react-stiletto-components/fonts/fonts.css'

import theme from './theme'
import { version } from '../package.json'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import App from './report-ui'


const isProduction = process.env.REACT_APP_ENV === 'production'
// const isDev = process.env.REACT_APP_ENV === 'development'

// if (isDev) {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React, {
// trackAllPureComponents: true,
// trackExtraHooks: [
//   [ReactRedux, 'useSelector'],
// ],
//   })
// }

const useStyles = makeStyles(theme => ({
  subHeader: {
    opacity: 0.66,
  },
}))

const LoginFormWrapper = (props) => {
  const classes = useStyles()

  return (
    <Box display="flex" width="100%" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={4} pb={2}>
        <Typography variant="h2" component="h1">
          Pavers Reporting Tool
        </Typography>
        <Typography
          className={classes.subHeader}
          component="h2"
          paragraph
          variant="h5"
        >
          Version {version}
        </Typography>
      </Box>
      <LoginForm {...props} />
    </Box>
  )
}

const authProviderMode = isProduction ? 'live' : 'test'

ReactDOM.render(
  <AuthProvider mode={authProviderMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticatedComponent
        blockedComponent={<LoginFormWrapper />}
        render={(auth) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box minHeight="100vh" display="flex" minWidth="100vw">
              <StilettoToolbar
                auth={auth}
                title={`Pavers Reporting Tool v${version}`}
                username={auth.user?.first_name ?? auth.username}
              />
              <App baseUrl={process.env.REACT_APP_API_BASE_URL} accessToken={auth.accessToken} isProduction={isProduction} username={auth.user?.first_name ?? auth.username} />
            </Box>
          </MuiPickersUtilsProvider>
        )}
        requiredPermissions="STILETTO"
        onLogout={() => {
          // this a bit hacky, but to use react-helmet (as in App.js) we'd have to
          // significantly refactor (lift activeReport state up here...)
          document.title = 'Pavers Reporting Tool'
        }}
      />
    </ThemeProvider>
  </AuthProvider>,
  document.getElementById('root'),
)
